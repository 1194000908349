<template>
  <ModalLayout title="Edit User">
    <div class="rounded-t-[10px] bg-white pt-[41px]">
      <div
        @click.prevent="$emit('close')"
        class="absolute right-[24px] top-[36px] flex h-[32px] w-[32px] cursor-pointer items-center justify-center rounded-full border border-gpx_gray-800 sm:right-[40px]"
      >
        <XMarkIcon
          class="heroicon-stroke-w-1.4 h-[20px] w-[20px] text-gpx_gray-200"
        />
      </div>
      <div
        class="mx-[24px] border-b border-b-gpx_gray-800 pb-[18px] sm:mx-[40px]"
      >
        <div class="mb-[2px] text-gpx_sm font-medium text-gpx_gray-200">
          Editing {{ user.first_name }} Permissions
        </div>
        <div
          v-if="account"
          class="text-gpx_2xl font-semibold text-lms_black-500"
        >
          {{ account.title }}
        </div>
      </div>
      <div class="px-[24px] sm:px-[40px]">
        <div class="mt-[30px]">
          <div class="mb-[3px] text-gpx_base font-semibold text-lms_black-400">
            User Role
          </div>
          <div class="text-gpx_sm text-gpx_gray-100">
            Grant user access to all or a specific devices.
          </div>
        </div>
        <div class="mt-[30px]" v-if="isLoading">
          <Spinner class="mr-[5px] h-[14px] w-[16px] self-center" />
        </div>
        <div v-else>
          <UIRadioGroup class="mt-[19px]">
            <UIRadioGroupOption
              v-model="form.role"
              :selected="ROLE.admin"
              :label="'Administrator'"
              :description="'Access to all devices, can invite users'"
            />
            <UIRadioGroupOption
              v-model="form.role"
              :selected="ROLE.all"
              :label="'All Devices'"
              :description="'Access to all devices, can not invite users'"
            />
            <UIRadioGroupOption
              v-model="form.role"
              :selected="ROLE.devices"
              :label="'Specific Devices'"
              :description="'Access only specific devices'"
            />
          </UIRadioGroup>
        </div>
        <div
          v-if="form.role === ROLE.devices && devicesLoading"
          class="mt-[29px] text-xs text-gpx_gray-200"
        >
          Loading devices...
        </div>
        <DeviceAutocomplete
          v-if="form.role === ROLE.devices && !devicesLoading"
          class="mt-[29px]"
          v-model="form.devices"
          :selected-options="form.devices"
        />
      </div>
    </div>
    <div class="rounded-b-[10px] bg-white px-[24px] py-[32px] sm:px-[40px]">
      <UIButton
        :loading="isSavingPermissions"
        :disabled="isSavingPermissions"
        :label="isSavingPermissions ? 'Saving' : 'Save Settings'"
        @click="handleEditUserPermissions"
      />
    </div>
  </ModalLayout>
</template>

<script>
import * as _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { useToast } from 'vue-toastification';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import ModalLayout from '@/layouts/ModalLayout.vue';
import DeviceAutocomplete from '@/components/device/_common/DeviceAutocomplete.vue';
import UIRadioGroup from '@/components/ui/RadioGroup/UIRadioGroup.vue';
import UIRadioGroupOption from '@/components/ui/RadioGroup/UIRadioGroupOption.vue';
import UIButton from '@/components/ui/UIButton.vue';
import Spinner from '@/components/_common/Spinner.vue';
import { ROLE } from '@/config/constants';

export default {
  components: {
    ModalLayout,
    XMarkIcon,
    UIRadioGroup,
    UIRadioGroupOption,
    UIButton,
    Spinner,
    DeviceAutocomplete,
  },

  emits: ['close'],
  props: ['params'],

  data() {
    return {
      user: {},
      devices: [],
      isLoading: true,
      devicesLoading: true,
      form: {
        account_id: null,
        role: ROLE.all,
        devices: [],
      },
      isSavingPermissions: false,
    };
  },

  computed: {
    ROLE() {
      return ROLE
    },
    ...mapGetters({
      accountId: 'auth/accountId',
      activeModal: 'modal/active',
    }),
    formRole() {
      return this.form.role;
    },
    account() {
      return _.find(this.user.accounts, { id: this.accountId });
    },
  },

  watch: {
    formRole(selectedRole) {
      if (selectedRole != ROLE.devices) {
        this.form.devices = [];
      }
    },
  },

  beforeMount() {
    this.form.account_id = this.accountId;
    this.fetchData();
  },

  methods: {
    ...mapActions({
      findUserByAccountId: 'user/findByAccountId',
      devicesByUserId: 'deviceMeta/fetchByUserId',
      updateUserPermissions: 'user/updateUserPermissions',
      fetchMe: 'auth/fetchMe',
    }),
    fetchData() {
      this.findUserByAccountId({
        userId: +this.params.userId,
      })
        .then((res) => {
          this.user = res.data.data;
          this.form.role = this.user.account_roles[this.accountId];
        })
        .finally(() => (this.isLoading = false));

      this.devicesByUserId({
        id: +this.params.userId,
        params: {
          account: +this.accountId,
        },
      })
        .then((res) => {
          this.devices = res.data.data;
          this.form.devices = _.map(res.data.data, (device) => {
            return { value: device.id, label: device.name };
          });
        })
        .finally(() => (this.devicesLoading = false));
    },
    handleEditUserPermissions() {
      const toast = useToast();
      this.isSavingPermissions = true;
      if (this.form.role != ROLE.devices) {
        this.form.devices = [];
      }
      this.updateUserPermissions({
        params: {
          ...this.form,
          email: this.user.email,
        },
      })
        .then(() => {
          toast.success('The user permissions were successfully updated');
          this.activeModal.params.onSuccess();
          this.$emit('close');
          if (this.form.account_id === this.accountId) {
            this.fetchMe();
          }
        })
        .catch((e) => {
          if (e.response) {
            toast.error(e.response.data.message);
          } else {
            toast.error('An error ocurred. Please try again later.');
          }
        })
        .finally(() => (this.isSavingPermissions = false));
    },
  },
};
</script>
